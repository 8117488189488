var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c(
        "v-main",
        { staticClass: "pt-0", attrs: { fluid: "", tag: "section" } },
        [
          _c("PlainTopBar", { ref: "topBar", attrs: { title: "Captures" } }),
          _c("section", { staticClass: "pt-16" }, [
            _c("div", { staticClass: "d-flex justify-center" }, [
              _c(
                "div",
                [
                  _c(
                    "p",
                    { ref: "title", staticClass: "caption mb-2" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mb-1", attrs: { size: "16px" } },
                        [_vm._v(" " + _vm._s(_vm.mdiCameraIris) + " ")]
                      ),
                      _vm._v(" Captures History ")
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-0" },
                        [
                          _c("v-data-table", {
                            staticClass: "pa-0 ma-0 cursor-pointer",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.captures,
                              "item-key": "file_id",
                              "fixed-header": "",
                              height: _vm.tableHeight
                            },
                            on: { "click:row": _vm.openCaptureLink },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.created_on",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formatDateTime(item)) +
                                          " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.file_extension",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.formatType(item)) + " "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.actions",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "#00A9F4" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.copyCaptureLink(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiContentCopy))
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "", color: "#00A9F4" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.openCaptureLink(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiOpenInNew))
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }