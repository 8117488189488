<template>
  <v-app-bar color="#3F51B5" class="white--text py-0 my-0" height="56px" app>
    <div class="d-flex justify-space-between align-center">
      <div class="white--text">
        <div>
          {{ title }}
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "PlainTopBar",
  props: {
    title: String,
  },
};
</script>
